import React from "react";

import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className="bg-[#121212] text-white">
        <div className="container bg-[#121212] py-12 mx-auto px-4 flex flex-col lg:flex-row justify-between">
          {/* About Section */}
          <div className="mb-8 lg:mb-0">
            <h2 className="font-bold mb-4">ABOUT</h2>
            <p className="mb-4 text-sm lg:text-base">
              TOVIVU BILISIM YAZILIM DIJITAL VE MEDYA YAYINCILIK ANONIM SIRKETI
            </p>
            <div className="flex space-x-4">
              <Link href="#">
                <i className="fab fa-facebook"></i>
              </Link>
              <Link href="#">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link href="#">
                <i className="fab fa-linkedin"></i>
              </Link>
            </div>
            <div className="mt-4 flex space-x-4">
              <img
                src="https://i.hizliresim.com/odww0gx.png"
                alt="Mastercard"
                className="h-10"
              />
              <img
                src="https://i.hizliresim.com/slfh0ww.png"
                alt="Visa"
                className="h-10"
              />
            </div>
          </div>

          {/* Contact Section */}
          <div className="mb-8 lg:mb-0">
            <h2 className="font-bold mb-4">CONTACT</h2>
            <p className=" text-sm lg:text-base">
              <i class="fa-solid fa-location-dot mr-4 text-[#cc571a]"></i>
              MUALLIMKÖY MAH. DENIZ CAD. MUALLIMKÖY T.G.B. 1.ETAP SITESI 1.1.C1
              BLOK NO: 143/8 IÇ KAPI NO: Z01
            </p>
            <p className="mt-4  text-sm lg:text-base">
              Bilişim Vadisi Türkiye / Kocaeli – Gebze
            </p>
            <p className="mt-4  text-sm lg:text-base">
              <i class="fa-solid fa-envelope mr-4 text-[#cc571a]"></i>
              info@tovivu.com
            </p>
            <p className="mt-4  text-sm lg:text-base">
              <i class="fa-solid fa-clock mr-4 text-[#cc571a]"></i>
              İletişim Saatleri: 09.00 | 23.00
            </p>
          </div>

          {/* Privacy Policies Section */}
          <div className="mb-8 lg:mb-0">
            <h2 className="font-bold mb-4">EN KISA SÜREDE GÖRÜŞELİM</h2>
            <p>
              <Link
                to="https://tovivu.com/gizlilik-politikasi/"
                className="text-white hover:text-white"
              >
                Gizlilik Politikaları
              </Link>
            </p>
          </div>
        </div>
      </footer>
      <div className="flex items-center justify-center flex-row w-full">
        <div className="container  w-[100vw] bg-black  p-8">
          <p className="text-center text-white  text-sm lg:text-base">
            Copyright © 2024. Powered by Tovivu
          </p>
          <div className="text-center mt-4 flex justify-center flex-col lg:flex-row space-x-4 text-gray-200">
            <Link
              to="https://tovivu.com/tovivu-kisisel-satis-sozlesmesi/"
              className="hover:text-white  text-sm lg:text-base"
            >
              Kişisel Satış Sözleşmesi
            </Link>
            <Link
              to="https://tovivu.com/tovivu-iptal-ve-iade-kosullari/"
              className="hover:text-white  text-sm lg:text-base my-2 lg:my-0"
            >
              İptal ve İade Koşulları
            </Link>
            <Link
              to="https://tovivu.com/tovivu-mesafeli-satis-sozlesmesi/"
              className="hover:text-white  text-sm lg:text-base"
            >
              Mesafeli Satış Sözleşmesi
            </Link>
            <Link
              to="https://tovivu.com/tovivu-gizlilik-sozlesmesi/"
              className="hover:text-white text-sm lg:text-base my-2 lg:my-0"
            >
              Gizlilik Sözleşmesi
            </Link>
            <Link
              to="https://tovivu.com/kullanim-sartlari/"
              className="hover:text-white text-sm lg:text-base"
            >
              Kullanım Şartları
            </Link>
            <Link
              to="tel:+905434533198"
              className="hover:text-white text-sm lg:text-base  my-2 lg:my-0"
            >
              +90 (543) 453 3198
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
