import React from "react";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="bg-black p-2 mx-auto w-full lg:text-[0.8vw] text-sm">
      <p className="text-yellow-400 text-center mb-2">
        <Link
          target="_blank"
          to={"https://tovivu.com/tovivu-mesafeli-satis-sozlesmesi/"}
          className="underline"
        >
          Mesafeli Satış Sözleşmesi
        </Link>
        ,{" "}
        <Link
          target="_blank"
          to={"https://tovivu.com/tovivu-iptal-ve-iade-kosullari/"}
          className="underline"
        >
          İptal/İade Sözleşmesi
        </Link>{" "}
        <span className="text-white">ve </span>
        <Link
          target="_blank"
          to={"https://tovivu.com/tovivu-kisisel-satis-sozlesmesi/"}
          className="underline"
        >
          Kişisel Satış Sözleşmesi
        </Link>{" "}
        <span className="text-white">için tıklayın.</span>
      </p>
      <p className="text-white text-center mb-2">
        Devam ederek kabul etmiş sayılırsınız.
      </p>
      <p className="text-white text-center my-10">
        Ödemenizle ilgili problem mi var?{" "}
        <Link
          to="mailto:destek@tovivu.com"
          className="text-yellow-400 underline"
        >
          Bize Ulaşın
        </Link>
        .
      </p>
    </div>
  );
}

export default Privacy;
