import React from "react";

const PacketBox = ({ packet, current, handleBuy, discount, currentPacket }) => {
  const handlePrintFeature = (feature) => {
    try {
      const correctedDescription = feature.replace(/'/g, '"');
      const parsedDescription = JSON.parse(correctedDescription);
      return parsedDescription;
    } catch (e) {
      console.error(e);
    }
  };

  const formatPrice = (price) => {
    return price.toLocaleString("tr-TR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const applyDiscount = (price) => {
    const isDiscountApplicable =
      discount &&
      discount.packets &&
      discount.packets.some(
        (discountPacket) => discountPacket.packet_id === packet.id
      );

    if (!isDiscountApplicable || discount.discount <= 0) return price;

    let discountedPrice = price;

    if (discount.type === "yuzde") {
      discountedPrice = price - price * (discount.discount / 100);
    } else if (discount.type === "miktar") {
      discountedPrice = price - discount.discount;
    }

    return discountedPrice > 0 ? discountedPrice : 0;
  };

  const finalPrice = applyDiscount(packet.price);

  return (
    <div className="rounded-3xl m-5">
      <div
        className="box relative bg-black bg-opacity-50 text-white p-8 w-[350px] lg:w-96 backdrop-filter backdrop-blur-lg  lg:text-[1vw] text-sm min-h-[450px] flex flex-col items-center justify-center"
        style={{
          background: "rgba(0, 0, 0, 0.5)",
        }}
      >
        {currentPacket === packet.id && (
          <div className="currentLevelArea bg-yellow-400 py-2 px-5 rounded-xl mb-4">
            <h1 className="text-black font-semibold text-sm">
              Şuan bu pakete sahipsiniz
            </h1>
          </div>
        )}
        <div className="relative z-10">
          <h2 className="font-bold mb-2">{packet.name}</h2>
          <p className="text-sm mb-4">{packet.packet_text}</p>
          <ul className="mb-6 text-sm list-disc mx-4">
            {handlePrintFeature(packet.description).map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          <div className="flex items-center mb-6 justify-center">
            {packet.old_price > finalPrice && finalPrice > 0 && (
              <div className="bg-yellow-500 text-black rounded-full px-2 py-3 text-base font-bold mr-2 flex flex-row items-center justify-center">
                <h1 className="text-xs lg:text-sm mr-1">Kazancınız: </h1>
                <h1 className="text-sm lg:text-base">
                  {formatPrice(packet.old_price - finalPrice)} ₺
                </h1>
              </div>
            )}
            <div className={`${packet.old_price > 0 ? "ml-3" : "ml-0"}`}>
              <div className="flex flex-row items-center justify-center">
                <div className="flex flex-col items-center justify-center">
                  {packet.old_price > 0 && (
                    <>
                      <h1 className="text-sm mr-1 text-yellow-500 font-semibold">
                        İndirim
                      </h1>
                      <h1 className="relative inline-block text-base font-light">
                        <span className=" relative z-10 text-white text-sm lg:text-base">
                          {formatPrice(packet.old_price)} ₺
                        </span>
                        <span className="absolute left-0 right-0 top-1/2 h-[2px] bg-yellow-500 transform -rotate-6 z-0"></span>
                      </h1>
                    </>
                  )}
                  {finalPrice > 0 && packet.old_price > 0 && (
                    <>
                      <div className="flex flex-row items-center justify-center mt-1">
                        <h1 className="text-xs lg:text-base mr-2">Tutar</h1>
                        <h1 className="text-sm lg:text-lg font-bold">
                          {formatPrice(finalPrice)} ₺
                        </h1>
                      </div>
                    </>
                  )}
                  {finalPrice > 0 && parseInt(packet.old_price) === 0 && (
                    <>
                      <div className="flex flex-row items-center justify-center my-5">
                        <h1 className="text-xs lg:text-lg mr-2">Tutar</h1>
                        <h1 className="text-sm lg:text-lg font-bold">
                          {formatPrice(finalPrice)} ₺
                        </h1>
                      </div>
                    </>
                  )}
                </div>
                {finalPrice <= 0 && (
                  <h1 className="text-lg font-bold my-7">Ücretsiz</h1>
                )}
              </div>
            </div>
          </div>
          <button
            onClick={() => handleBuy(packet.id)}
            disabled={currentPacket === packet.id}
            className={`py-3 px-4 rounded-full flex items-center justify-center w-full lg:text-[0.8vw] font-light transition-all duration-300 
    ${
      currentPacket === packet.id
        ? "bg-gray-400 text-white cursor-not-allowed"
        : "bg-[#2f2f2e] hover:bg-yellow-400 hover:text-black text-white"
    }`}
          >
            {currentPacket === packet.id
              ? "Zaten bu pakete sahipsiniz"
              : packet.id === 1
              ? "Ücretsiz pakete geç"
              : "Satın almaya devam et"}

            {currentPacket !== packet.id && <span className="ml-2">➔</span>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PacketBox;
