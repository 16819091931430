import * as React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Main from "./components/main";
import PaymentSuccesful from "./components/paymentSuccesful";

function App() {
  return (
    <>
      <Routes>
        <Route path="/*" element={<Main />} />
        <Route path="/payment-succesful" element={<PaymentSuccesful />} />
      </Routes>
    </>
  );
}

export default App;
